const Other = () => {
  return (
    <>
      <h2>Other</h2>
      <div style={{display: "flex", justifyContent: "center"}}>
        <h6>Under construction.</h6>
      </div>
    </>
  );
};

export default Other;